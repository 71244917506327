<template>
  <SectionHeader></SectionHeader>
  <SectionMission></SectionMission>
  <SectionAbout></SectionAbout>
  <SectionFeatures></SectionFeatures>
  <SectionPricing></SectionPricing>
  <SectionLogos></SectionLogos>
  <SectionCta></SectionCta>
  <SectionNewsletter></SectionNewsletter>
  <SectionFooter></SectionFooter>
</template>

<script>
import SectionHeader from '@/components/SectionHeader';
import SectionAbout from '@/components/SectionAbout';
import SectionFeatures from '@/components/SectionFeatures';
import SectionNewsletter from '@/components/SectionNewsletter';
import SectionFooter from '@/components/SectionFooter';
import SectionMission from '@/components/SectionMission';
import SectionPricing from '@/components/SectionPricing';
import SectionCta from '@/components/SectionCta';
import SectionLogos from '@/components/SectionLogos';

export default {
  components: {
    SectionHeader,
    SectionAbout,
    SectionFeatures,
    SectionFooter,
    SectionNewsletter,
    SectionMission,
    SectionPricing,
    SectionCta,
    SectionLogos,
  }
}
</script>

<style>
</style>
