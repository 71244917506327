<template>
  <div class="bg-white border-t border-gray-200">
    <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
      <p class="text-center text-lg font-semibold text-gray-600">Zaufali nam między innymi</p>
      <div class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-20" src="/logo-footballtrial.png" alt="Football Trial" />
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-20" src="/logo-kowica.png" alt="Kotwica Kołobrzeg" />
        </div>
        <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-20" src="/logo-juventus.png" alt="Juventus Academy Bydgoszcz" />
        </div>
        <div class="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
          <img class="h-20" src="/logo-rks-okecie-warszawa.jpeg" alt="RKSA Okęcie Warszawa" />
        </div>
        <div class="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
          <img class="h-20" src="/logo-ks-brzoza.png" alt="KS Brzoza" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionLogos"
}
</script>

<style scoped>

</style>