<template>
  <div id="features" class="overflow-hidden bg-gray-50">
    <div class="relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
      <svg class="absolute top-0 left-full -translate-x-1/2 -translate-y-3/4 transform lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
      </svg>

      <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div class="lg:col-span-1">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Znajdź to czego chcesz, kiedy chcesz i gdzie chcesz.</h2>
        </div>
        <dl class="mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0">
          <div v-for="feature in features" :key="feature.name">
            <dt>
              <div class="flex h-12 w-12 items-center justify-center rounded-md bg-primary text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div>
              <p class="mt-5 text-lg font-medium leading-6 text-gray-900">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BoltIcon, EnvelopeIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/vue/24/outline'

const features = [


  {
    name: 'Wyszukiwarka',
    description:
        'Sprawdź ogłoszenia dodane na Spoglo w Twojej okolicy lub w dowolnym miejscu na świecie. Filtruj ogłoszenia po najdrobniejszych szczegółach i zapisuj je, by później do nich wrócić.',
    icon: ScaleIcon,
  },
  {
    name: 'Powiadomienia',
    description: 'Jeżeli spodziewasz się odpowiedzi od potencjalnego, nowego zawodnika lub czekasz na nowe ogłoszenia, nie musisz stale zerkać na telefon – aplikacja wyśle do Ciebie powiadomienie.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Czat',
    description:
        'Skorzystaj z wbudowanego czatu, aby zadawać pytania do ogłoszeń, ustalać warunki współpracy i na żywo rozmawiać z innymi użytkownikami. Jeżeli masz CV w pdfie, to możesz je wysłać na czacie.',
    icon: BoltIcon,
  },
  {
    name: 'i wiele innych...',
    description:
        'Na tym jednak nie kończy się lista praktycznych, przydatnych funkcjonalności. Obserwuj innych użytkowników, udostępniaj ogłoszenia w sieci i skorzystaj z promowania ogłoszeń.',
    icon: EnvelopeIcon,
  },
]
</script>