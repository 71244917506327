<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div class="rounded-3xl bg-primary py-10 px-6 sm:py-16 sm:px-12 lg:flex lg:items-center lg:p-20">
        <div class="lg:w-0 lg:flex-1">
          <h2 class="text-3xl font-bold tracking-tight text-white">Zapisz się do newslettera</h2>
          <p class="mt-4 max-w-3xl text-lg text-indigo-100">
            Aby pozostać na bieżąco ze wszystkimi nowościami i zmianami w aplikacji, a także korzystać z dodatkowych możliwości i promocji, zapisz się do naszego newslettera!
          </p>
        </div>
        <div class="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
          <form class="sm:flex" @submit.prevent>
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email-address" type="email" autocomplete="email" required="" class="w-full rounded-md border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700" placeholder="Podaj adres email" v-model="form.email" />
            <InvisibleRecaptcha
              id="newsletter-submit"
              type="submit"
              sitekey="6LcAJeAfAAAAAFeyV2KBKC9tTd68Il84BYO3cr4y"
              class="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-black hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
              :disabled="form.loading"
              :validate="onValidate"
              :callback="onSubmit"
            >
              Zapisz
            </InvisibleRecaptcha>
          </form>
          <p class="mt-3 text-sm text-indigo-100" v-if="form.success">
            Email został zapisany!
          </p>
          <p class="mt-3 text-sm text-indigo-100">
            Dbamy o ochronę Twoich danych. Przeczytaj naszą
            {{ ' ' }}
            <a href="https://www.spoglo.com/polityka-prywatnosci" class="font-medium text-white underline">Politykę Prywatności.</a>
            Formularz jest chroniony przez reCAPTCHA - <a href="https://policies.google.com/privacy" class="font-medium text-white underline">Polityka Prywatności</a> oraz <a href="https://policies.google.com/terms" class="font-medium text-white underline">Warunki Usługi</a> Google.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import axios from 'axios';

export default {
  name: "SectionNewsletter",
  components: {
    InvisibleRecaptcha,
  },
  data() {
    return {
      form: {
        loading: false,
        success: false,
        email: '',
      }
    }
  },
  methods: {
    onValidate() {
      this.loading = true;
    },
    onSubmit(recaptchaToken) {
      if (!this.form.email) {
        return;
      }
      axios.post('https://www.spoglo.com/api/v1/newsletter/subscribe', {
        email: this.form.email,
        recaptcha_token: recaptchaToken,
      })
          .then((response) => {
            this.form.email = '';
            this.form.success = true;
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>