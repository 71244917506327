<template>
  <div id="pricing" class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="pb-16 xl:flex xl:items-center xl:justify-between">
        <div>
          <h1 class="text-4xl font-bold tracking-tight sm:text-5xl">
            <span class="text-gray-900">Dodaj ogłoszenie za</span>
            {{ ' ' }}
            <span class="text-primary">15 zł</span>
          </h1>
          <p class="mt-5 text-xl text-gray-500">Odświeżanie za darmo, do skutku.</p>
        </div>
        <a href="https://www.spoglo.com/cennik?utm_source=spoglo&utm_medium=landing&utm_campaign=about&utm_id=Spoglo+About+Landing" class="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0">Sprawdź cennik</a>
      </div>
      <div class="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
        <div>
          <h2 class="text-lg font-semibold text-primary">Proste zasady</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900">Brak ukrytych opłat</p>
          <p class="mt-4 text-lg text-gray-500">
              Nasz model działalności jest banalnie prosty. Płacisz za dodanie ogłoszenia i opcjonalne dodatki. Wszystkie funkcje w aplikacji są dostępne za darmo.
          </p>
        </div>
        <div class="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
          <ul role="list" class="divide-y divide-gray-200">
            <template v-for="(feature, featureIdx) in features.slice(0, 5)" :key="feature">
              <li v-if="featureIdx === 0" class="flex py-4 md:py-0 md:pb-4">
                <CheckIcon class="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span class="ml-3 text-base text-gray-500">{{ feature }}</span>
              </li>
              <li v-else class="flex py-4">
                <CheckIcon class="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span class="ml-3 text-base text-gray-500">{{ feature }}</span>
              </li>
            </template>
          </ul>
          <ul role="list" class="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0">
            <template v-for="(feature, featureIdx) in features.slice(5)" :key="feature">
              <li v-if="featureIdx === 0" class="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                <CheckIcon class="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span class="ml-3 text-base text-gray-500">{{ feature }}</span>
              </li>
              <li v-else class="flex py-4">
                <CheckIcon class="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span class="ml-3 text-base text-gray-500">{{ feature }}</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/24/outline'

const features = [
    'Dodawaj i edytuj ogłoszenia do woli',
    'Uzupełnij swój profil o szczegółowe informacje',
    'Wyszukuj i przeglądaj ogłoszenia innych',
    'Kontaktuj się w sprawie ogłoszeń',
    'Otrzymuj powiadomienia o ogłoszeniach',
    'Udostępniaj ogłoszenia swoim znajomym',
    'Sprawdzaj statystyki wyświetleń ogłoszeń',
    'Obserwuj innych użytkowników',
    'Zapisuj ogłoszenia do ulubionych',
    'Aplikuj do ogłoszeń',
]
</script>