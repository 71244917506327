<template>
  <div class="bg-gray-50">
    <div class="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        <span class="block">Zacznij już teraz!</span>
        <span class="block text-primary">Dodaj swoje pierwsze ogłoszenie</span>
      </h2>
      <div class="mt-8 flex justify-center">
        <div class="inline-flex rounded-md shadow">
          <a href="https://www.spoglo.com/zarejestruj-sie?utm_source=spoglo&utm_medium=landing&utm_campaign=about&utm_id=Spoglo+About+Landing" class="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-5 py-3 text-base font-medium text-white hover:bg-indigo-700">Zarejestruj się</a>
        </div>
      </div>
    </div>
  </div>
</template>